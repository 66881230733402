//throttle
!(function (n, t) {
	"$:nomunge";
	var u,
		e = n.jQuery || n.Cowboy || (n.Cowboy = {});
	(e.throttle = u = function (n, u, o, i) {
		function r() {
			function e() {
				(c = +new Date()), o.apply(f, d);
			}
			function r() {
				a = t;
			}
			var f = this,
				g = +new Date() - c,
				d = arguments;
			i && !a && e(), a && clearTimeout(a), i === t && g > n ? e() : u !== !0 && (a = setTimeout(i ? r : e, i === t ? n - g : n));
		}
		var a,
			c = 0;
		return "boolean" != typeof u && ((i = o), (o = u), (u = t)), e.guid && (r.guid = o.guid = o.guid || e.guid++), r;
	}),
		(e.debounce = function (n, e, o) {
			return o === t ? u(n, e, !1) : u(n, o, e !== !1);
		});
})(window);
var resource = resource || {};
resource.Event = resource.Event || {};
resource.Event.emitter = {
	subscribers: {},
	on: function (e, t, n) {
		this.subscribers[e] = this.subscribers[e] || [];
		this.subscribers[e].push({ callback: t, context: n });
	},
	off: function (e, t, n) {
		var r,
			i = this.subscribers[e],
			s;
		if (i) {
			r = i.length - 1;
			while (r >= 0) {
				s = i[r];
				if (s.callback === t && (!n || s.context === n)) {
					i.splice(r, 1);
					break;
				}
				r--;
			}
		}
	},
	emit: function (e) {
		var t = this.subscribers[e],
			n = 0,
			r = Array.prototype.slice.call(arguments, 1),
			i;
		if (t) {
			while (n < t.length) {
				i = t[n];
				i.callback.apply(i.context || this, r);
				n++;
			}
		}
	},
};
resource.util = $.extend(
	{
		hasElement(el) {
			return $(el).length > 0;
		},
	},
	resource.util || {}
);
resource.View = resource.View || {};
resource.View.floating = resource.View.floating || {};
resource.View.floating = function (htOptions) {
	$.extend(this, htOptions || {});
	this.init();
};
resource.View.floating.prototype = $.extend(
	{
		init() {
			this._assignElements();
			this._attachEventHandlers();
		},
		_assignElements() {
			this._window = $(window);
			this._bodyParent = $("body");

			// this._welOpen = $("._js_btn_cmhelp");
			this._welOpenBig = $(".cmhelp_floating_open");
			this._welHelpWrap = $("#frame_cmhelp");
			// this._welIframeHelpWrap = $("#frame_cmhelp", parent.document);
			this._welClose = this._welHelpWrap.find(".btn_close");
			this._welViewType = this._welHelpWrap.find(".btn_view");
			this._welfaqToggle = $(".cmhelp_faq .faq_toggle");

			// this._welContentWrap = $(this.sContWrap);
			// this._welFooter = $(this.sFooter);
		},
		_attachEventHandlers() {
			this._welOpenBig.on("click", $.proxy(this._onClickOpenBig, this));
			this._welClose.on("click", $.proxy(this._onClickClose, this));
			this._welViewType.on("click", $.proxy(this._onClickViewType, this));
		},
		_onClickOpenBig(e) {
			var welTarget = $(e.currentTarget);
			welTarget.hide();
			this.emit("openFloating");
			this._welHelpWrap.show();
		},
		_onClickClose() {
			this._welHelpWrap.hide();

			if (this._welOpenBig.length) {
				this._welOpenBig.show();
			}
		},
		_onClickViewType(e) {
			var welTarget = $(e.currentTarget);
			var welParent = $("#frame_cmhelp", parent.document);
			if (welParent.hasClass("ty_simple")) {
				welTarget.attr("aria-expanded", "false");
				welParent.removeClass("ty_simple");
				welTarget.removeClass("active");
			} else {
				welTarget.attr("aria-expanded", "true");
				welParent.addClass("ty_simple");
				welTarget.addClass("active");
			}
		},
	},
	resource.Event.emitter
);
resource.View.commonfloating = function (options) {
	const df = {
		sContWrap: "#frame_container",
		sFooter: "#cmfooter",
	};
	$.extend(this, options || df);

	this.init();
};

resource.View.commonfloating.prototype = resource.View.floating.prototype;

$(function () {
	$("body").on("click", ".cmhelp_faq .faq_toggle", function (e) {
		var welTarget = $(this);
		var welParent = welTarget.closest(".list_item");
		var welChild = welParent.find("> .cmhelp_list");

		e.stopPropagation();
		if (welChild.length) {
			e.preventDefault();

			if (welParent.hasClass("on")) {
				welTarget.attr("aria-expanded", "false");
				welParent.removeClass("on");
			} else {
				welTarget.attr("aria-expanded", "true");
				welParent.addClass("on");
			}
		}
	});
});
